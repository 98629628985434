import React from 'react';
import RestaurantRoundedIcon from '@mui/icons-material/RestaurantRounded';
import { rtColors } from '@ritualco/ritual-frontend-components';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import SelectionBox from '@artemis/components/SelectionBox';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import { toggleCutlery } from '@artemis/store/cart/slice';

const Container = styled.div`
  margin-bottom: 16px;
`;

const StyleldSelectionBox = styled(SelectionBox)`
  justify-content: space-between;
  &:hover {
    background: none;
  }
`;

const Cutlery = ({ className, merchantId, includeCutlery, disabled }) => {
  const dispatch = useDispatch();

  const handleCutleryToggle = () => {
    dispatch(toggleCutlery({ merchantId, includeCutlery: !includeCutlery }));
  };

  return (
    <Container className={className}>
      <StyleldSelectionBox
        type="checkbox"
        onClick={handleCutleryToggle}
        selected={includeCutlery}
        labelPosition="left"
        disabled={disabled}
      >
        <p>
          <RestaurantRoundedIcon
            sx={{
              color: disabled ? rtColors.black500 : rtColors.black800,
              fontSize: 19,
              marginRight: '12px',
              verticalAlign: 'middle',
            }}
          />
          <FormattedMessage entry="cart.includeCutlery" />
        </p>
      </StyleldSelectionBox>
    </Container>
  );
};

Cutlery.propTypes = {
  className: PropTypes.string,
  includeCutlery: PropTypes.bool,
  merchantId: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Cutlery;
