import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import { getTextForOptions } from '@artemis/containers/Cart/utils';
import { CartItemShape } from './propTypes';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ItemName = styled.div`
  ${props => props.theme.typography.body};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
`;

const ItemNote = styled.p`
  color: ${props => props.theme.palette.grey[400]};
  ${props => props.theme.typography.subtitle2};
  font-style: italic;
`;

const Option = styled.div`
  color: ${props => props.theme.palette.grey[600]};
  ${props => props.theme.typography.caption};
`;

const OriginalPrice = styled.p`
  color: ${props => props.theme.palette.grey[600]};
  ${props => props.theme.typography.caption};
`;

const RemoveFromCart = styled.button`
  color: ${props => props.theme.palette.primary};
  width: fit-content;
  background-color: transparent;
  border: none;
  padding: 0px;
  ${props => props.theme.typography.bodySmall};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  transition: color 0.25s ease;

  &:hover {
    color: ${({ theme }) =>
      theme.palette.hover.onLightBg(theme.palette.primary)};
    text-decoration: underline;
  }
`;

const ItemDetails = ({ item, onRemoveFromCart, currencyCode }) => (
  <Container>
    <ItemName>{item.title}</ItemName>
    <Option>
      {getTextForOptions({ options: item.cartItemOptions, currencyCode })}
    </Option>
    <ItemNote>{item.note}</ItemNote>
    {item.perk && (
      <OriginalPrice>
        <FormattedMessage
          entry="cart.perk.originalPrice"
          values={{ price: item.perk.originalPrice }}
        />
      </OriginalPrice>
    )}
    {onRemoveFromCart && (
      <RemoveFromCart
        data-testid={`remove-${item.cartItemId}`}
        onClick={e => {
          onRemoveFromCart({
            item: item.cartItemId,
          });
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <FormattedMessage entry="cart.remove" />
      </RemoveFromCart>
    )}
  </Container>
);
ItemDetails.propTypes = {
  item: CartItemShape,
  onRemoveFromCart: PropTypes.func,
  currencyCode: PropTypes.string.isRequired,
};

export default ItemDetails;
