import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PerkShape } from '@artemis/components/MenuGroup/propTypes';

const Container = styled.div`
  ${props => props.theme.typography.body};
`;

const PrimaryText = styled.span`
  color: ${({ theme }) => theme.palette.primary};
`;

const ItemPrice = ({ price, perk }) => {
  if (perk) {
    return (
      <Container>
        <PrimaryText>{perk.discountedPrice}</PrimaryText>
      </Container>
    );
  }

  return <Container>{price}</Container>;
};

ItemPrice.propTypes = {
  price: PropTypes.string,
  perk: PerkShape,
};

export default ItemPrice;
