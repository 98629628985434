/**
 *
 * Cart
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ItemPrice from './ItemPrice';
import { CartItemShape } from './propTypes';
import ItemDetails from './ItemDetails';

const Container = styled.div`
  overflow-y: hidden;
  & > :not(:last-child) {
    border-bottom: none;
  }
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-top: 1px solid ${props => props.theme.palette.grey[100]};
  border-bottom: 1px solid ${props => props.theme.palette.grey[100]};
  ${props => props.shouldModify && 'cursor: pointer'};
`;

const Quantity = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.theme.typography.bodySmall};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  width: 24px;
  height: 24px;
  background: ${props => props.theme.palette.grey[100]};
  border-radius: 4pt;
  margin-right: 16px;
`;

const Cart = ({
  items = [],
  onModifyItem,
  onRemoveFromCart,
  currencyCode,
  className,
}) => {
  const shouldModify = !!onModifyItem;
  return (
    <Container className={className}>
      {items.map(item => (
        <Item
          key={`${item.cartItemId}`}
          shouldModify={shouldModify}
          onClick={() =>
            shouldModify &&
            onModifyItem({
              cartItemId: item.cartItemId,
              itemId: item.menuItemId,
              options: item.cartItemOptions,
              quantity: item.quantity,
              note: item.note,
              perk: item.perk,
            })
          }
        >
          <Quantity>{item.quantity}</Quantity>
          <ItemDetails
            item={item}
            onRemoveFromCart={onRemoveFromCart}
            currencyCode={currencyCode}
          />
          <ItemPrice price={item.price} perk={item.perk || null} />
        </Item>
      ))}
    </Container>
  );
};

Cart.propTypes = {
  items: PropTypes.arrayOf(CartItemShape),
  onModifyItem: PropTypes.func,
  onRemoveFromCart: PropTypes.func,
  currencyCode: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Cart;
