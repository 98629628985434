import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthenticationContext from '@artemis/integrations/auth/AuthenticationContext';
import { loadCart } from '@artemis/store/cart/slice';
import { getMerchantId } from '@artemis/store/merchant/selectors';
import { formatIncrement } from '@artemis/components/ItemSelection/utils';
import { formatCurrency } from '@artemis/utils/currency-format';

export const useLoadCart = (isPreview = false) => {
  const dispatch = useDispatch();
  const merchantId = useSelector(getMerchantId);
  const { initialized, authenticated, redirectedFromAuth } = useContext(
    AuthenticationContext,
  );

  useEffect(() => {
    if (initialized && !redirectedFromAuth && !isPreview) {
      dispatch(loadCart({ merchantId }));
    }
  }, [initialized, authenticated]);
};

/**
 * This function groups all occurrences of the same ID together, summing up the increment counts and prices
 * It supports both cart item options and order item options, which are similar but have slightly different shapes
 *
 * For cart item options: Athena groups options with the same ID and price together.
 * There may be more than one group when choice has a free quantity and the user adds a quantity greater than the free quantity.
 *
 * For order item options: Athena returns a separate option for each increment
 */
const groupOptionsById = options => {
  const map = options.reduce((result, option) => {
    const optionId = option.menuItemOptionId;

    const entry = result[optionId] || {
      ...option,
      title: option.title,
      incrementNumerator: option.incrementNumerator || 1,
      incrementDenominator: option.incrementDenominator || 1,
      totalPriceMicro: 0,
      count: 0,
    };

    const incrementCount = option.incrementCount || 1;

    entry.totalPriceMicro += option.priceMicro * incrementCount;
    entry.count += incrementCount;

    return {
      ...result,
      [optionId]: entry,
    };
  }, {});

  return Object.values(map);
};

// Example: "6x Apples ($2.50)"
const getTextForGroupedOption = ({ groupedOption, currencyCode }) => {
  const parts = [];

  // Prefix with option quantity
  if (groupedOption.count > 1) {
    parts.push(
      `${formatIncrement({
        incrementCount: groupedOption.count,
        incrementNumerator: groupedOption.incrementNumerator,
        incrementDenominator: groupedOption.incrementDenominator,
      })}x`,
    );
  }

  // Option title
  parts.push(groupedOption.title);

  // Total price
  if (groupedOption.totalPriceMicro > 0) {
    const price = formatCurrency({
      value: groupedOption.totalPriceMicro,
      isMicro: true,
      currencyCode,
    });
    parts.push(`(${price})`);
  }

  return parts.join(' ');
};

export const getTextForOptions = ({ options, currencyCode }) =>
  groupOptionsById(options)
    .map(groupedOption =>
      getTextForGroupedOption({ groupedOption, currencyCode }),
    )
    .join(', ');
