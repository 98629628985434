import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import {
  hasActiveCurrencyCredits,
  getActiveCurrencyCredits,
} from '@artemis/store/user/selectors';

const CartMessage = styled.p`
  ${props => props.theme.typography.body};
  color: ${props => props.theme.palette.grey[600]};
  text-align: center;
  margin-bottom: 52px;
`;

const FooterTitle = styled.h3`
  margin-top: 16px;
  margin-bottom: 16px;
  ${props => props.theme.typography.body};
  font-weight: ${props => props.theme.typography.fontWeightBold};
  margin-bottom: 16px;
  text-align: center;
`;

const FooterMessage = styled.p`
  ${props => props.theme.typography.caption};
  color: ${props => props.theme.palette.grey[600]};
  text-align: center;
`;

const FooterCreditMessage = styled.p`
  ${props => props.theme.typography.bodySmall};
  text-align: center;
`;

const Separator = styled.hr`
  border-color: ${props => props.theme.palette.grey['300']};
  border-style: solid;
  width: 100%;
`;

const CartImage = styled(ResponsiveImage)`
  margin: 60px auto 20px auto;
  height: 40px;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;

  > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

const EmptyCart = ({ className, isDineIn, tableName, isInstoreOrder }) => {
  const hasCredits = useSelector(hasActiveCurrencyCredits);
  const userCredits = useSelector(getActiveCurrencyCredits);

  return (
    <Container className={className}>
      <Separator />
      <CartImage id="cart.cart.img" />
      <CartMessage>
        <FormattedMessage entry="cart.emptyCart" />
      </CartMessage>
      {hasCredits ? (
        <>
          <Separator />
          <FooterCreditMessage>
            <FormattedMessage
              entry="cart.credits"
              values={{
                credits: userCredits,
                bold: text => <strong>{text}</strong>,
              }}
            />
          </FooterCreditMessage>
        </>
      ) : (
        isDineIn &&
        !isInstoreOrder && (
          <>
            <Separator />
            <FooterTitle>
              <FormattedMessage entry="cart.dineInInstruction" />
            </FooterTitle>
            <FooterMessage>
              <FormattedMessage
                entry="cart.orderForTable"
                values={{ tableName: decodeURIComponent(tableName) }}
              />
            </FooterMessage>
          </>
        )
      )}
    </Container>
  );
};

EmptyCart.propTypes = {
  className: PropTypes.string,
  isDineIn: PropTypes.bool,
  tableName: PropTypes.string,
  isInstoreOrder: PropTypes.bool,
};

export default EmptyCart;
