import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import styled from 'styled-components';
import {
  getIsDeliveryCart,
  getMinimumDeliveryOrderValueMicro,
  getFreeOrderValueMicro,
  getMeetsFreeOrderValue,
  getFreeOrderValue,
  getRemainingFreeOrderValue,
  getMinimumPickupOrderValueMicro,
  getMaximumPickupOrderValueMicro,
  getCartOrderValue,
  getMeetsPickupItemCountCriteria,
} from '@artemis/store/cart/selectors';
import { getIsGroupOrder } from '@artemis/store/groupOrder/selectors';
import OrderCriteriaMessage from './OrderCriteriaMessage';

const InfoCaption = styled.span`
  ${props => props.theme.typography.bodySmall};
  color: ${props => props.theme.rtColors.black900};
`;

const SuccessCaption = styled.span`
  ${props => props.theme.typography.bodySmall};
  color: ${props => props.theme.rtColors.green500};
`;

const DeliveryMessage = ({ className }) => {
  const isGroupOrder = useSelector(getIsGroupOrder);
  const isDeliveryCart = useSelector(getIsDeliveryCart);

  const meetsPickupItemCountCriteria = useSelector(
    getMeetsPickupItemCountCriteria,
  );

  const orderValue = useSelector(getCartOrderValue);
  const minimumOrderValue = useSelector(
    isDeliveryCart
      ? getMinimumDeliveryOrderValueMicro
      : getMinimumPickupOrderValueMicro,
  );
  const maximumOrderValue = useSelector(
    isDeliveryCart ? () => null : getMaximumPickupOrderValueMicro,
  );
  const orderValueFallsOutOfMinMax =
    (minimumOrderValue && orderValue < minimumOrderValue) ||
    (maximumOrderValue && orderValue > maximumOrderValue);

  const hasFreeOrderValue = useSelector(getFreeOrderValueMicro);
  const meetsFreeOrderValue = useSelector(getMeetsFreeOrderValue);
  const freeOrderValue = useSelector(getFreeOrderValue);
  const remainingFreeOrderValue = useSelector(getRemainingFreeOrderValue);

  if (isGroupOrder) {
    return null;
  }

  if (!meetsPickupItemCountCriteria || orderValueFallsOutOfMinMax) {
    return (
      <OrderCriteriaMessage
        min={minimumOrderValue}
        max={maximumOrderValue}
        className={className}
      />
    );
  }

  if (isDeliveryCart && hasFreeOrderValue && !meetsFreeOrderValue) {
    return (
      <InfoCaption className={className}>
        <FormattedMessage
          entry="cart.freeOrderNotMet"
          values={{ freeOrderValue, remainingFreeOrderValue }}
        />
      </InfoCaption>
    );
  }

  if (isDeliveryCart && meetsFreeOrderValue) {
    return (
      <SuccessCaption className={className}>
        <FormattedMessage entry="cart.freeDeliveryOrder" />
      </SuccessCaption>
    );
  }

  return null;
};

DeliveryMessage.propTypes = {
  className: PropTypes.string,
};

export default DeliveryMessage;
