import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import {
  getCurrencyCode,
  getCartOrderValue,
  getItemsInCart,
  getMeetsPickupItemCountCriteria,
  getPickupMinCartItemCount,
  getPickupMaxCartItemCount,
} from '@artemis/store/cart/selectors';
import { formatCurrency } from '@artemis/utils/currency-format';

const ErrorCaption = styled.span`
  ${props => props.theme.typography.bodySmall};
  color: ${props => props.theme.rtColors.red500};
`;

const OrderCriteriaMessage = ({ min, max, className }) => {
  const orderValue = useSelector(getCartOrderValue);
  const currencyCode = useSelector(getCurrencyCode);
  const numCartItems = useSelector(getItemsInCart);
  const meetsPickupItemCountCriteria = useSelector(
    getMeetsPickupItemCountCriteria,
  );
  const pickupMinCartItemCount = useSelector(getPickupMinCartItemCount);
  const pickupMaxCartItemCount = useSelector(getPickupMaxCartItemCount);

  if (!meetsPickupItemCountCriteria) {
    if (pickupMinCartItemCount && numCartItems < pickupMinCartItemCount) {
      return (
        <ErrorCaption className={className}>
          <FormattedMessage
            entry="cart.orderItemCountNotMet"
            values={{
              count: pickupMinCartItemCount,
            }}
          />
        </ErrorCaption>
      );
    }
    if (pickupMaxCartItemCount && numCartItems > pickupMaxCartItemCount) {
      return (
        <ErrorCaption className={className}>
          <FormattedMessage
            entry="cart.orderItemCountExceeded"
            values={{
              count: pickupMaxCartItemCount,
            }}
          />
        </ErrorCaption>
      );
    }
  }

  if (min && orderValue < min) {
    const extraValueNeeded = min - orderValue;

    return (
      <ErrorCaption className={className}>
        <FormattedMessage
          entry="cart.orderMinimumNotMet"
          values={{
            minimumOrderValue: formatCurrency({ value: min, currencyCode }),
            extraValueNeeded: formatCurrency({
              value: extraValueNeeded,
              currencyCode,
            }),
          }}
        />
      </ErrorCaption>
    );
  }

  if (max && orderValue > max) {
    const valueExceeded = orderValue - max;

    return (
      <ErrorCaption className={className}>
        <FormattedMessage
          entry="cart.orderMaximumExceeded"
          values={{
            maximumOrderValue: formatCurrency({ value: max, currencyCode }),
            valueExceeded: formatCurrency({
              value: valueExceeded,
              currencyCode,
            }),
          }}
        />
      </ErrorCaption>
    );
  }

  return null;
};

OrderCriteriaMessage.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  className: PropTypes.string,
};

export default OrderCriteriaMessage;
