/**
 *
 * Scheduling Selectors
 *
 */

import { createSelector } from 'reselect';

const getSchedulingRoot = state => state.scheduling;

export const getIsSchedulingModalOpen = createSelector(
  getSchedulingRoot,
  scheduling => scheduling && scheduling.isModalOpen,
);

export const getScheduledLoading = createSelector(
  getSchedulingRoot,
  scheduling => scheduling && scheduling.isLoading,
);

export const getSchedulingError = createSelector(
  getSchedulingRoot,
  scheduling => scheduling && scheduling.error && scheduling.error.error,
);

export const getIsCheckout = createSelector(
  getSchedulingRoot,
  scheduling => scheduling && scheduling.isCheckout,
);
